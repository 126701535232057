@import "main-bx";

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: $white;
    &__inner {
        padding: 10px 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        @include below(1000px) {
            padding: 10px 25px;
        }

        @include below(860px) {
            padding: 10px;
            max-width: 375px;
        }
    }

    &__logo {
        width: 100%;
        max-width: 264px;
        display: block;

        @include below(1600px) {
            max-width: 180px;
        }

        @include below(1380px) {
            max-width: 112px;
        }

        @include below(860px) {
            max-width: 80px;
        }
    }

    &__dealer {
        margin-right: auto;
        margin-left: 15px;
        padding-left: 15px;
        position: relative;

        @include below(860px) {
            display: none;
        }

        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: 0;
            width: 1px;
            height: 25px;
            background-color: $color_grey;
        }

        &-title {
            @include fontProperty(400, 24px, 29px, $color_dark);
            margin-bottom: 3px;

            @include below(1520px) {
                font-size: 20px;
            }
        }

        &-text {
            @include fontProperty(400, 16px, 19px);
            color: rgba(105, 114, 121, 0.6);
        }
    }

    &__address {
        @include fontProperty(400, 16px, 19px, $color_dark);
        margin-right: 30px;

        @include below(1300px) {
            display: none;
        }
    }

    &__contacts {
        display: flex;
        align-items: center;
        @include below(860px) {
            display: none;
        }
        a {
            @include fontProperty(600, 26px, 31px, $color_dark);
            transition: all .2s linear;
            text-transform: uppercase;
            margin-right: 30px;
            position: relative;

            @include below(1600px) {
                font-size: 22px;
            }

            @include below(1520px) {
                font-size: 18px;
            }
            
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                background-color: $color_dark;
                transition: all .2s linear;
                transform: scaleX(0);
            }

            &:hover {
                &::before {
                    transform: scaleX(1);
                }
            }
        }

        &-btn {
            padding: 12px 30px; 
        }
    }

    &__burger {
        display: none;
        width: 20px;
        height: 20px;
        padding: 10px;
        position: relative;
        @include below(860px) {
            display: block;
        }

        &.active {
            &::before {
                transform: rotate(45deg) translateY(5px);
            }

            &::after {
                transform: rotate(-45deg) translateY(-5px);
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #000000;
            transition: all .2s linear;
        }

        &::before {
            top: 5px;
        }

        &::after {
            bottom: 5px;
        }
    }
}