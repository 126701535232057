@import "main-bx";

.section {

    &_01 {
        padding-top: 80px;
        padding-bottom: 76px;

        @include below(1200px) {
            padding-bottom: 32px;
        }

        @include below(768px) {
            padding-top: 0;
        }
    }

    &_02 {
        padding-bottom: 110px;

        @include below(1440px) {
            padding-bottom: 60px;
        } 

        @include below(768px) {
            padding-bottom: 36px;
        }
    }

    &_03 {
        padding-bottom: 120px;
        @include below(1440px) {
            padding-bottom: 60px;
        }

        @include below(768px) {
            padding-bottom: 40px;
        }
    }

    &__title {
        @include fontProperty(600, 40px, 48px, $color_dark);
        text-align: center;

        @include below(1200px) {
            font-size: 34px;
            line-height: 40px;
        }

        @include below(768px) {
            font-size: 24px;
            line-height: 29px;
        }
    }
}