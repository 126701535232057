@import "main-bx";

.map {
    &__wrap {
        display: flex;
        align-items: center;

        @include below(768px) {
            flex-direction: column;
            width: 100%;
            max-width: 375px;
            margin: 0 auto;
        }
    }

    &__container {
        width: 100%;
        max-width: 1492px;
        height: 600px;
        margin-left: auto;

        @include below(768px) {
            max-width: 100%;
            height: 375px;
        }
    }

    &__title {
        text-align: left;
        margin-bottom: 20px;
    }

    &__contacts {
        width: 100%;
        max-width: 422px;
        padding: 50px;
        background: #fff;
        transform: translateX(250px);
        position: relative;
        z-index: 100;
        .btn {
            padding: 21px 40px;
        }

        &-item {
            margin-bottom: 20px;
        }
        
        &-key {
            @include fontProperty(400, 12px, 14px, $color_dark);
            opacity: .8;
            margin-bottom: 12px;

           
        }

        &-val {
            @include fontProperty(400, 15px, 100%, $color_dark);

            a {
                color: $color_dark;

                &:hover {
                    opacity: .8;
                }
            }
        }

        @include below(1380px) {
            margin-left: -150px;
        }

        @include below(768px) {
            transform: translateX(0);
            margin-left: 0;
            max-width: 100%;

            padding: 30px 10px 70px;
        }
    }
}