$base_fonts: Arial,sans-serif;
$accent_font: "KiaSignature";

$black: #000000;
$white: #fff;

$color_dark:#05141F;
$color_grey: #CDD0D2;


$color_dark2:#4D4D4F;
$color_dark3: #6D6E71;
$color_Bred: #A50034;
$color_slates: #A7A9AC;
$color_lease: #FAFBFB;
$color_ladata: #F3F4F4;
$color_ebwhite: #EBEBEB;

$body_width: 1920px;

$breaks: (
    xl: 1180px,
    lg: 1024px,
    mlg: 992px,
    md: 720px,
    sm: 540px,
    xs: 100%,
);

$container: (
    xl: 1460px,
    lg: 1024px,
    mlg: 992px,
    md: 720px,
    sm: 540px,
    xs: 100%, 
);
