@import "main-bx";

.models__buttons {
    .btn_dark,
    .btn_light {
        span {
            font-size: 20px;
        }
    }
}

.btn {
    @include fontProperty(600, 18px, 18px, $white);
    transition: all .2s linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #05141F;
    box-shadow: none;

    @include below(768px) {
        width: 100%;
        justify-content: center;
    }

    span {
        display: block;
        transition: all .2s linear;
        position: relative;
        padding-bottom: 2px;
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            transition: all .2s linear;
            transform: scaleX(0);
        }
    }

    &_dark {
        background-color: #05141F;
        span {

            &::before {
                background-color: $white;
            }
        }

        &:hover {
            span {
                &::before {
                    transform: scaleX(1);
                }
            }
        }

        &:active {
            background-color: #25282a;
            span {
                border-color: transparent;

                &::before {
                    transform: scaleX(0.8);
                }
            }
        }
    }

    &_light {
        background-color: #fff;

        span {
            color: #05141F;
            &::before {
                background-color: #05141F;
            }
        }

        &:hover {
            span {
                &::before {
                    transform: scaleX(1);
                }
            }
        }

        &:active {
            background-color: #e1e2e2;
            span {
                border-color: transparent;

                &::before {
                    transform: scaleX(0.8);
                }
            }
        }
    }

    &:disabled {
        span {
            opacity: 0;
        }
        .btn__spin {
            opacity: 1;
        }
    }

    &__spin {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        opacity: 0;
    }

}
