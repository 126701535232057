@import "main-bx";

.tab {
    @include below(1200px) {
        overflow-x: auto;
    }
    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;

        @include below(1200px) {
            // width: 1200px;
            justify-content: flex-start;
            padding: 40px 0;
        }
    }

    &__item {
        position: relative;
        @include fontProperty(600, 18px, 22px, $color_dark);
        padding-top: 10px;
        opacity: .6;
        cursor: pointer;

        transition: all .2s linear;

        @include below(1200px) {
            margin-right: 22px;
            white-space: nowrap;
        }

        &::after,
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background-color: $color_dark;
            transition: all .2s linear;
        }

        &::before {
            top: 0;
            bottom: auto;
            width: 100%;
            height: 4px;
            opacity: 0;
            transform: translateY(20px);
        }

        &.active {
            opacity: 1;
            cursor: auto;
            &::before {
                opacity: 1;
                transform: translateY(0);
            }
            &::after {
                content: none;
            }
        }

        &:hover {
            opacity: 1;
            &::after {
                width: 100%;
            }
        }
    }
}