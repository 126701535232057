@import "main-bx";

.intro {
    position: relative;
    margin-bottom: 80px;
    @include below(768px) {
        margin-bottom: 0;
    }
    &__slider {
        height: 610px;

        @include below(768px) {
            height: auto;
        }

        .slick-list,
        .slick-track,
        .intro__img {
            height: 100%;

            @include below(768px) {
                height: auto;
            }
        }

        .slick-arrow {
            z-index: 20;
            width: 60px;
            height: 60px;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s linear;

            @include below(1600px) {
                top: 65%;
            }

            &::before {
                content: none;
            }


            &:hover {
                background-color: rgba(0, 0, 0, 0.9);
            }
            img {
                width: 20px;
                height: 20px;
            }
        }

        .slick-prev {
            left: 80px;
            @include below(1600px) {
                left: 40px;
            }
        }

        .slick-next {
            right: 80px;
            @include below(1600px) {
                right: 40px;
            }
        }

        .slick-dots {
            li {
                width: 40px;
                height: 2px;
                background-color: #CDD0D2;
            
                &.slick-active {
                    background-color: #000;
                }
            }
            button {
                display: none;
            }
        }
    }

    &__slider-item {
        position: relative;

        &:not(:first-child) {
            display: none;
        }

        &.slick-slide {
            display: block;
        }
    }

    &__content {
        position: absolute;
        top: 180px;
        left: 0;
        right: 0;
        
        &-inner {
            width: 100%;
            max-width: 650px;
        }

        @include below(768px) {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            margin-top: 10px;
        }
    }

    &__title {
        @include fontProperty(600, 58px, 70px, $white);
        letter-spacing: -0.02em;
        margin-bottom: 18px;

        @include below(1600px) {
            font-size: 45px;
            line-height: 120%;
        }

        @include below(768px) {
            color: $color_dark;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 10px;
        }
    }

    &__text {
        @include fontProperty(600, 36px, 43px, $white);
        @include below(1600px) {
            font-size: 30px;
            line-height: 120%;
        }

        @include below(768px) {
            color: $color_dark;
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__btn {
        padding: 20px 42px;
    }

    &__bottom {
        position: absolute;
        width: 100%;
        bottom: -80px;
        left: 0;
        right: 0;
        
        @include below(1440px) {
            bottom: -160px;
        }

        @include below(768px) {
            position: relative;
            bottom: auto;
            left: auto;
            right: auto;
            margin-top: 40px;
        }

        &-wrap {
            background-color: $white;
            border: 1px solid #CDD0D2;
            padding: 36px 30px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include below(1500px) {
                flex-wrap: wrap;
            }
            
            @include below(768px) {
                padding: 20px 10px; 
            }
        }

        &-item {
            &:nth-child(1) {
                @include below(1500px) {
                    width: 100%;
                }
            }

            &:nth-child(2),
            &:nth-child(3) {
                @include below(1500px) {
                    width: 50%;
                }

                @include below(900px) {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }

        &-title {
            @include fontProperty(600, 40px, 48px, $color_dark);
            letter-spacing: -0.02em;
            padding-bottom: 10px;

            @include below(1600px) {
                font-size: 30px;
                line-height: 120%;
            }

            @include below(768px) {
                font-size: 20px;
                line-height: 24px;
                text-align: center;
            }
        }

        &-text {
            @include fontProperty(400, 25px, 30px, $color_dark);
            @include below(1600px) {
                font-size: 20px;
                line-height: 120%;
            }

            @include below(768px) {
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                margin-bottom: 25px;
            }
        }
    }

    &__form {
        width: 100%;
        max-width: 440px;
        &-row {
            display: flex;
            align-items: center;

            @include below(768px) {
                flex-direction: column;
            }
        }
        .form__check {
            margin-top: 20px;
        }
        .form__label {
            margin-right: 10px;
            @include below(768px) {
               margin-right: 0;
               margin-bottom: 21px;
            }
        }
        .form__btn {
            padding: 12px 30px;
            @include below(768px) {
                padding: 11px 0;
            }
            span {
                white-space: nowrap;
            }
        }

        &__policy {
            font-size: 12px;
            line-height: 14px;
            padding-right: 40px;
        }
    }
}