@import "main-bx";

.models {
    overflow: hidden;
    &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        background-color: rgba(246, 247, 248, 0.7);
        padding: 7px 20px;

        margin-bottom: 100px;

        @include below(1440px) {
            margin-bottom: 60px;
        }

        @include below(768px) {
            padding: 15px;
            margin-bottom: 40px;
        }

        &_reverse {
            flex-direction: row-reverse;
            .models__img img {
                margin-left: 0;
                margin-right: -50px;

                @include below(1500px) {
                    margin: 0;
                    object-position: -140px 0;
                }

                @include below(1000px) {
                    object-position: 0 0;
                }
            }

            .models__gift-item_star {
                right: auto;
                left: 39%;

                @include below(1500px) {
                    left: 30%;
                }

                @include below(1330px) {
                    left: 25%;
                }

                @include below(1200px) {
                    left: 25px;
                }

                @include below(1000px) {
                    left: auto;
                    right: 54px;
                }

                @include below(768px) {
                    right: auto;
                }
            }
        }

        @include below(1000px) {
            flex-direction: column-reverse;
        }

        &-col {
            &:first-child {
                padding: 34px 20px;

                @include below(768px) {
                    padding: 0;
                }
            }
        }
    }

    &__head {
        display: flex;
        align-items: flex-start;
        margin-bottom: 17px;

        @include below(768px) {
            margin-bottom: 15px;
        }
    }

    &__title {
        @include fontProperty(400, 50px, 100%, $color_dark);
        margin-right: 18px;
        white-space: nowrap;

        @include below(768px) {
            font-size: 24px;
            line-height: 100%;
            margin-right: 10px;
        }
    }

    &__die {
        @include fontProperty(600, 15px, 100%, $color_dark);
        text-transform: uppercase;
        padding: 8px 17px;
        background-color: rgba(5, 20, 31, 0.05);
        white-space: nowrap;

        @include below(768px) {
            font-size: 8px;
            line-height: 100%;
            padding: 5px;
        }
    }

    &__gift {
        margin-bottom: 10px;
        &-item {
            display: flex;
            align-items: center;
            @include below(768px) {
                margin-bottom: 15px;
            }
            &_star {
                position: absolute;
                top: 36px;
                right: 54px;

                @include below(768px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                }
            }
        }

        &-text {
            @include fontProperty(600, 18px, 18px, $color_dark);
            text-transform: uppercase;
            margin-left: 10px;

            @include below(768px) {
                font-size: 16px;
                line-height: 100%;
            }
        }

        &-icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #05141F;
            img {
                width: 30px;
                height: 30px;

                @include below(768px) {
                    width: 20px;
                    height: 20px;
                }
            }

            @include below(768px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    &__scope {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        @include below(768px) {
            margin-bottom: 30px;
        }
        &-col {
            width: 50%;
        }

        &-item {
            min-height: 75px;
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        &-key {
            @include fontProperty(400, 15px, 18px, $color_dark);
            opacity: 0.8;
            margin-bottom: 5px;

            @include below(768px) {
                font-size: 14px;
                line-height: 17px;
            }
        }

        &-val {
            padding-right: 10px;
            @include fontProperty(600, 23px, 100%, $color_dark);
            @include below(768px) {
                font-size: 20px;
            }
            &-min {
                font-weight: 500;
                font-size: 20px;
                line-height: 100%;
                color: #05141F;
            }
            sup {
                font-size: 12px;
                top: -12px;
                left: 3px;
            }
        }
    }

    &__img {
        @include below(1500px) {
            height: 100%;
            display: flex;
            align-items: center;
        }
        img {
            width: auto;
            max-width: inherit;
            margin-left: -245px;
            margin-top: -54px;
            margin-bottom: -71px;

            @include below(1500px) {
                margin: 0;
                width: 100%;
                max-width: 100%;
                height: 80%;
            }

            @include below(1200px) {
                height: 70%;
            }

            @include below(768px) {
                height: 100%;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        @include below(768px) {
            flex-direction: column;
        }
        .btn {
            padding: 21px 40px;
            white-space: nowrap;
        }

        .btn_dark {
            margin-right: 10px;

            @include below(768px) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}
