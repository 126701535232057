@import "main-bx";

.odds {
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        padding: 40px 60px 0;
        margin: -60px 0 0 -60px;

        @include below(1320px) {
            margin: -50px 0 0 -50px;
        }

        @include below(1125px) {
            margin: -30px 0 0 -30px;
            justify-content: space-between;
        }

        @include below(768px) {
            margin: -10px 0 0;
            padding: 20px 0 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 288px;
        height: 180px;
        padding: 30px;

        background: rgba(246, 247, 248, 0.3);

        margin: 60px 0 0 60px;

        @include below(1320px) {
            margin: 50px 0 0 50px;
        }

        @include below(1125px) {
            margin: 30px 0 0 30px;
        }

        @include below(1085px) {
            max-width: 45%;
        }

        @include below(768px) {
            margin: 10px 0 0;
            max-width: 48%;
            padding: 10px 10px 30px;
            height: auto;
        }
    }

    &__icon {
        width: 100%;
        max-width: 60px;
        height: 60px;
        margin-bottom: 20px;
    } 

    &__text {
        @include fontProperty(400, 18px, 22px, $color_dark);
        text-align: center;

        @include below(768px) {
            font-size: 14px;
            line-height: 17px;
        }
    }
}