@import "main-bx";

.footer {
    background-color: $color_dark;

    &__inner {
        padding: 60px 230px 60px 60px;

        @include below(1070px) {
            padding: 60px;
        }

        @include below(768px) {
            width: 100%;
            max-width: 375px;
            margin: 0 auto;
            padding: 40px 10px 30px; 
        }
    }

    &__text {
        @include fontProperty(400, 12px, 14px, #697279);

        &_first {
            width: 100%;
            max-width: 783px;

            @include below(1380px) {
                max-width: 450px;
            }

            @include below(768px) {
                margin-top: 40px;
            }
        }
    }

    &__row {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 30px;
        }

        &:nth-child(2) {
            align-items: flex-start;
        }
        &_beetwen {
            justify-content: space-between;
        }

        @include below(768px) {
            flex-wrap: wrap;
        }

        &:nth-child(3) {
            @include below(768px) {
                flex-direction: column-reverse;
            }
        }

        .btn {
            border-color: $white;
            padding: 21px 40px;
            white-space: nowrap;
        }
    }

    &__logo {
        img {
            width: 90px;
            height: 21px;
        }
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid #fff;

        @include below(768px) {
            margin-right: 10px;
            padding-right: 10px;
        }
    }

    &__dealer {
        &-title {
            @include fontProperty(400, 15px, 18px, $white);
            margin-bottom: 3px;

        }

        &-text {
            @include fontProperty(400, 12px, 14px, $white);
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        margin-top: -15px;
        padding-top: 15px;
        @include below(1270px) {
            flex-wrap: wrap;
        }

        @include below(768px) {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 0;
            margin-bottom: 40px;
            padding-top: 0;
        }

        &-item {
            &:not(:last-child) {
                margin-right: 90px;

                @include below(1500px) {
                    margin-right: 25px;
                }

                @include below(768px) {
                    margin-right: 0;
                }
            }

            @include below(768px) {
                margin-top: 15px;
            }
            @include below(768px) {
                width: 100%;
                margin-top: 0;
                padding: 20px 0;
                border-bottom: 1px solid rgba(105, 114, 121, 0.6);
            }
        }

        a {
            @include fontProperty(600, 18px, 22px, $white);
            transition: all .2s linear;
            &:hover {
                opacity: 0.8;
            }

            @include below(768px) {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    &__phone {
        &-text {
            @include fontProperty(400, 12px, 14px, $white);
            margin-bottom: 15px;
        }

        a {
            @include fontProperty(400, 15px, 18px, $white);
            border-bottom: 1px solid transparent;
            transition: all .2s linear;
            &:hover {
                border-bottom-color: $white;
            }
        }

    }

    &__sites {
        @include below(768px) {
            margin-bottom: 30px;
        }
        a {
            &:first-child {
                margin-right: 22px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}